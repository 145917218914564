
// 团队管理/体验卡页面
import { defineComponent , onBeforeMount, reactive, ref,Ref, toRefs } from 'vue';
import { getCardStock,getCardHistory } from '@/api/teamManage/vipCard';

import teamExperienceModal from '@/components/teamManage/teamExperienceModal.vue'
import { message } from 'ant-design-vue';
export default defineComponent({
    name:"teamExperience",
    components:{
        teamExperienceModal
    },
    setup(){
        const activeKey:Ref<string> = ref('1');
        // 查询条件
        const oldValue:Ref<string> = ref('');
        const total = ref<number>(0)
        const query = reactive({
          page:1,
          per_page:10,
          card_number:'',
          type:2,
          stock:1
        })
        const hisTotal = ref<number>(0)
        const historyQuery = reactive({
          hisPage:1,
          hisPer_page:10,
          hisCard_number:'',
          type:2,
        })

        const modal = ref(null);
        // table
        const dataSource = ref<Array<any>>([])
        const columns =  [
          {title: '卡号',dataIndex: 'card_number', key: 'card_number',width:180},
          { title: '归属机构', dataIndex: 'organ',key: 'organ',},
          {title: '归属销售员',dataIndex: 'name',key: 'name',},
          {title: '使用会员', dataIndex: 'nickname', key: 'nickname'},
          {title: '时长',dataIndex: 'duration_name',key: 'duration_name',},
          {title: '状态', dataIndex: 'type',key: 'type',width:170},
          {title: '创建时间',dataIndex: 'create_time',key: 'create_time',},
          {title: '操作',dataIndex: 'operation', key: 'operation',},
        ]
        const historyColumns = [
          {title: '出卡',dataIndex: 'province',key: 'province',width:180},
          {title: '卡号',dataIndex: 'card_num',key: 'card_num',width:350},
          {title: '收卡',dataIndex: 'city',key: 'city',width:180},
          {title: '计划数',dataIndex: 'cards_num',key: 'cards_num',},
          {title: '被使用数',dataIndex: 'use_num',key: 'use_num', },
          {title: '成功数',dataIndex: 'success_num',key: 'success_num', },
          {title: '操作员',dataIndex: 'operator', key: 'operator'},
          {title: '操作时间', dataIndex: 'create_time',key: 'create_time'},
        ]
        const historyData = ref<Array<any>>([])
        const userInfo = ref()
       
        onBeforeMount(()=>{
          getCardStockData()
          getHistoryData()
          userInfo.value  = localStorage.getItem('userInfo')?JSON.parse(localStorage.getItem('userInfo') as string):null;  
        })

        const stockDist = (val:object,type:string):void=>{
            (modal.value as any).showModal(val,type)
        }
        // 查询 库存
        const getCardStockData = (isPage?:string):void =>{
          if(isPage == 'page'){
            query.page = 1;
          }
          
          if(!/^[\da-z]+$/i.test(query.card_number) && query.card_number != ''){
            message.error('只能输入数字或英文')
            return
          }
          getCardStock(query).then((res:any) =>{
            if(res.code == 200){
              dataSource.value = res.data;
              total.value = res.total;
            }
          })
        }
        // 查询 分配历史
        const getHistoryData = (isPage?:string):void =>{
          if(isPage == 'page'){
            historyQuery.hisPage = 1;
          }
          if(!/^[\da-z]+$/i.test(historyQuery.hisCard_number) && historyQuery.hisCard_number != ''){
            message.error('只能输入数字或英文')
            return
          }
          const data = {
            page:historyQuery.hisPage,
            per_page:historyQuery.hisPer_page,
            card_number:historyQuery.hisCard_number,
            type:historyQuery.type
          }
            getCardHistory(data).then((res:any) =>{
              if(res.code == 200){
                historyData.value = res.data;
                // dataSource.value = res.data;
                hisTotal.value = res.total;
              }
            })
        }

        // 库存&历史表格 页码发生改变
        const stockChange = (page:any):void =>{
          query.per_page = page.pageSize;
          query.page = page.current;
          getCardStockData()
        }

        const historyChange = (page:any):void =>{
          historyQuery.hisPer_page = page.pageSize;
          historyQuery.hisPage = page.current;
          getHistoryData()
        }

        // 刷新表格数据
        const refresh = ():void =>{
          getCardStockData()
          getHistoryData()
        }

       

        return{
            activeKey,
            dataSource,
            columns,
            modal,
            oldValue,
            total,
            hisTotal,
            historyData,
            historyColumns,
            userInfo,
            ...toRefs(historyQuery),
            ...toRefs(query),
            stockDist,
            getCardStockData,
            stockChange,
            historyChange,
            getHistoryData,
            refresh
        }
    }
})

