
import { defineComponent , nextTick, onBeforeMount, reactive, ref,Ref } from 'vue';
import { getUserStockList,cardDistribution,getActivity } from "@/api/teamManage/vipCard";

import { message } from 'ant-design-vue';
import { getCardType } from '@/api/teamManage';
export default defineComponent({
    name:"vipEntityModal",
    setup({},{emit}){
        const visible = ref<boolean>(false);
        const selectData = ref<Array<any>>([])
        const activityList = ref([])

        // 表单
        const formRef = ref()
        const formModal = reactive({
            number:1,
            city_code:null,
            card_id:'',
            change:'',
            type:2,
            activity_id:null,
            card_duration:null
        })
        const cardType = ref([])

        const modalRules:object = {
            number: [{ required:true,message: '请输入要分配的数量'}],
            city_code: [{ required: true, message: '请选择团队'},],
            card_duration: [{ required: true, message: '请选择分配卡时长'},],
        }
        onBeforeMount(()=>{
            getProvinceCityList()
            getActivityList()
            getCardTypeList()
        })
        

        const showModal = (val:any,type:string) => {
            visible.value = true;
            formModal.change = type;
            if(type == 'batch'){
                formModal.card_id = '';
            }else{
                formModal.card_id = val.id;
            }
        };
        // 取消分配
        const handleCancel = () => {
            visible.value = false;
            formModal.city_code = null;
            formModal.card_duration = null;
            formModal.activity_id = null;
            formRef.value.resetFields();
        };
        // 确认分配
        const handleOk = ():void =>{
            nextTick(()=>{
                (formRef.value as any).validate().then(() => {
                    cardDistribution(formModal).then((res:any)=>{
                        if(res.code == 200){
                            message.success(res.msg)
                            emit('ok')
                            handleCancel()
                        }else{
                            message.error(res.msg)
                        }
                    })
                }).catch((Error:any) => {
                    console.log('error', Error);
                });
            })
        }

        // 获取省市list
        const getProvinceCityList = ():void =>{
            getUserStockList({type:1}).then((res:any)=>{
                if(res.code == 200){
                    selectData.value = res.data;
                }
            })
        }

        // 获取活动列表
        const getActivityList = () =>{
          getActivity().then((res:any)=>{
              console.log(res);
              if(res.code == 200){
                  activityList.value = res.data;
              }
          })
        }

        const selectChange = (val:any)=>{
            console.log(val);
        }

        // 获取卡类型
        const getCardTypeList = ()=>{
            getCardType().then((res:any)=>{
                
                if(res.code == 200){
                    cardType.value = res.data;
                }
            })
        }

        return {
            visible,
            formRef,
            modalRules,
            formModal,
            selectData,
            showModal,
            handleCancel,
            handleOk,
            activityList,
            selectChange,
            cardType
        }
    }
})
