import { service } from "@/utils/request";
import { AxiosResponse } from "axios";
import { api } from "../baseUrl";

function setUrl(url:string,data:any){
    let strUrl = url;
    if(data){
        for (const key in data) {
            strUrl += `&${key}=${data[key]}`
        }
    }
    return strUrl
}


//vip实体卡&体验卡 库存
export const getCardStock =  (data:any): Promise<AxiosResponse<object>> =>{
    return service({
        method:'post',
        url:`${api}/agent-api/api/card/index`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}
//vip实体卡&体验卡 分配历史
export const getCardHistory =  (data:any): Promise<AxiosResponse<object>> =>{
    return service({
        method:'post',
        url:`${api}/agent-api/api/card/distHistory`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

//vip实体卡&体验卡 用户可分配的市代 城市
export const getUserStockList =  (data:object): Promise<AxiosResponse<object>> =>{
    return service({
        method:'post',
        url:`${api}/agent-api/api/user_city`,
        data,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}
//vip实体卡&体验卡 分配
export const cardDistribution =  (data:object): Promise<AxiosResponse<object>> =>{
    return service({
        method:'post',
        url:`${api}/agent-api/api/card/distribution`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

// 体验卡获取活动列表
export const getActivity = (data?:any): Promise<AxiosResponse<object>> =>{
    return service({
        method:'post',
        url:`${api}/agent-api/api/card/activityList`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}